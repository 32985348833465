import { CheckOutlined, CloseOutlined, MinusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { ReactComponent as EmptyCheck } from "../assets/icons/control/empty-checkbox.svg";

// this is used for boolean value icon and currently only used in Radio Button Group Control and table for boolean column type
// Not using IconWrapper here as Radio.Group will put IconWrapper in a <span> and cause alignment issues.
type BooleanIconProps = {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
};

const DEFAULT_STROKE_WIDTH = 30;

const StyledCheckOutlined = styled(CheckOutlined)<BooleanIconProps>`
  color: ${(props) => props.color ?? props.theme.colors.ACCENT_GREEN};
  svg {
    width: ${(props) =>
      props.width !== undefined ? `${props.width}px` : "1em"};
    height: ${(props) =>
      props.height !== undefined ? `${props.height}px` : "1em"};
  }
  svg path {
    stroke: ${(props) => props.theme.colors.ACCENT_GREEN};
    stroke-width: ${(props) => props.strokeWidth ?? DEFAULT_STROKE_WIDTH};
  }
`;

const StyledCloseOutlined = styled(CloseOutlined)<BooleanIconProps>`
  color: ${(props) => props.theme.colors.DANGER};
  svg {
    width: ${(props) =>
      props.width !== undefined ? `${props.width}px` : "1em"};
    height: ${(props) =>
      props.height !== undefined ? `${props.height}px` : "1em"};
  }
  svg path {
    stroke: ${(props) => props.theme.colors.DANGER};
    stroke-width: ${(props) => props.strokeWidth ?? DEFAULT_STROKE_WIDTH};
  }
`;

const StyledMinusOutlined = styled(MinusOutlined)<BooleanIconProps>`
  color: ${(props) => props.theme.colors.GREY_200};
  svg {
    width: ${(props) =>
      props.width !== undefined
        ? `${props.width}px`
        : "12px"}; // make minus shorter
    height: ${(props) =>
      props.height !== undefined ? `${props.height}px` : "1em"};
  }
  svg path {
    stroke: ${(props) => props.theme.colors.GREY_200};
    stroke-width: ${(props) => props.strokeWidth ?? DEFAULT_STROKE_WIDTH};
  }
`;

const StyledEmptyCheckbox = styled(EmptyCheck)<BooleanIconProps>`
  width: 18px;
  height: 18px;
  path {
    stroke: ${(props) => props.theme.colors.GREY_200};
    stroke-width: ${(props) => props.strokeWidth ?? DEFAULT_STROKE_WIDTH};
  }
`;

export const RadioButtonControlIcons = {
  EMPTY: (props: BooleanIconProps) => null,
  CHECK: (props: BooleanIconProps) => <StyledCheckOutlined {...props} />,
  CLOSE: (props: BooleanIconProps) => <StyledCloseOutlined {...props} />,
  MINUS: (props: BooleanIconProps) => <StyledMinusOutlined {...props} />,
  EMPTY_CHECKBOX: (props: BooleanIconProps) => (
    <span
      style={{
        top: "4px",
        position: "relative",
      }}
    >
      <StyledEmptyCheckbox {...props} />
    </span>
  ),
};

export type RadioButtonIconNames = keyof typeof RadioButtonControlIcons;
