import {
  AccessMode,
  IntegrationKind,
  OAUTH_CALLBACK_PATH,
} from "@superblocksteam/shared";
import { memo, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useRouteError,
  createMemoryRouter,
} from "react-router-dom";
import Auth0ProviderWithHistory from "auth/authProvider";
import AuthWrapper from "auth/authWrapper";
import { LoginComponent, LogoutComponent, SignupComponent } from "auth/utils";
import EmailVerificationWrapper from "components/app/EmailVerification/EmailVerificationWrapper";
import { IntercomOverlay } from "components/app/Intercom/IntercomOverlay";
import SurveyWrapper from "components/app/Survey/SurveyWrapper";
import { FullPageSpinner } from "components/ui/FullPageSpinner";
import {
  VIEW_ACCESS_TOKENS,
  VIEW_AGENTS,
  VIEW_GROUPS,
  VIEW_OBSERVABILITY,
  VIEW_PROFILES,
  VIEW_REPOSITORIES,
  VIEW_ROLES,
  VIEW_SECRET_STORES,
  VIEW_USERS,
  VIEW_GROUP_MEMBERS,
  MANAGE_GROUPS,
  READ_ORGANIZATION,
} from "constants/rbac";
import { JwtContextProvider } from "hooks/ui/JwtProvider";
import { ApplicationRoute, EditorRoute } from "legacy/constants/routes";
import AppViewerLoader from "legacy/pages/AppViewer/loader";
import ErrorPage from "legacy/pages/common/ErrorPage";
import PageLoadingBar from "legacy/pages/common/PageLoadingSpinner";
import PageNotFound from "legacy/pages/common/PageNotFound";
import {
  getSafeCrash,
  getSafeCrashCode,
  getSafeCrashReason,
} from "legacy/selectors/errorSelectors";
import { getAccessMode } from "legacy/selectors/usersSelectors";
import { AuthorizedPageWrapper } from "pages/AccessTokens/AuthorizedPageWrapper";
import EditorLoader from "pages/Editors/loader";
import {
  CatchRedirectEditor,
  WidgetsDeployedRedirect,
  WidgetsEditorRedirect,
  WidgetsPreviewRedirect,
} from "pages/Editors/redirects";
import { RoleAndPermissionsPages } from "pages/Permissions/constants";
import OAuthImplicitCallback from "pages/components/OAuthCallback";
import { ROLES_AND_PERMISSIONS_URL } from "pages/routes";
import { useTracingManager } from "tracing/hooks";
import { EmbedWrapper } from "utils/embed/EmbedWrapper";
import { isDev } from "utils/env";
import { ApiProfileReloader } from "./ApiProfileReloader";

const loadingIndicator = <PageLoadingBar />;

const LazyDowngradeModal = lazy(
  () => import(/* webpackChunkName: "modals" */ "pages/Home/DowngradeModal"),
);
const LazyPricingModal = lazy(
  () => import(/* webpackChunkName: "modals" */ "pages/Home/PricingModal"),
);
const LazyPurchasedModal = lazy(
  () => import(/* webpackChunkName: "modals" */ "pages/Home/PurchasedModal"),
);
const LazyQualificationModal = lazy(
  () =>
    import(/* webpackChunkName: "modals" */ "pages/Home/QualificationModal"),
);

function PrivateRoutes() {
  return (
    <AuthWrapper>
      <JwtContextProvider>
        <EmailVerificationWrapper>
          <SurveyWrapper>
            <Outlet />
          </SurveyWrapper>
        </EmailVerificationWrapper>
      </JwtContextProvider>
    </AuthWrapper>
  );
}

// NOTE: These are ONLY used in local development. In all other modes, the embed pages
// are served from index-embed / embedRouter
function EmbeddedPrivateRoutes() {
  return (
    <EmbedWrapper>
      <AuthWrapper>
        <JwtContextProvider>
          <EmailVerificationWrapper>
            <SurveyWrapper>
              <Outlet />
            </SurveyWrapper>
          </EmailVerificationWrapper>
        </JwtContextProvider>
      </AuthWrapper>
    </EmbedWrapper>
  );
}

const MemoizedEmbeddedPrivateRoutes = memo(EmbeddedPrivateRoutes);

const MemoizedPrivateRoutes = memo(PrivateRoutes);

async function lazyWidgetEditor() {
  const { default: WidgetsEditor } = await import(
    /* webpackChunkName: "editor" */ "legacy/pages/Editor/WidgetsEditor"
  );
  return { element: <WidgetsEditor /> };
}

async function lazyHome() {
  const { default: Home } = await import(
    /* webpackChunkName: "home" */ "pages/Home"
  );
  return {
    element: (
      <IntercomOverlay>
        <Home />
      </IntercomOverlay>
    ),
  };
}

async function lazyIntegrations() {
  const { default: Integrations } = await import(
    /* webpackChunkName: "integrations" */ "pages/Integrations"
  );
  return {
    element: (
      <IntercomOverlay>
        <Integrations />
      </IntercomOverlay>
    ),
  };
}

async function lazyIntegrationForm(kind: IntegrationKind) {
  const { IntegrationForm } = await import(
    /* webpackChunkName: "integration-form" */ "pages/Integrations/IntegrationForm"
  );
  return {
    element: (
      <IntercomOverlay>
        <IntegrationForm kind={kind} />
      </IntercomOverlay>
    ),
  };
}

async function lazyProfiles() {
  const { default: Profiles } = await import(
    /* webpackChunkName: "profiles" */ "pages/Profiles"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper permissions={[VIEW_PROFILES]}>
          <Profiles />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazyRepositories() {
  const { default: Repositories } = await import(
    /* webpackChunkName: "repositories" */ "pages/Repositories"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper permissions={[VIEW_REPOSITORIES]}>
          <Repositories />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazyRepositoryDetails() {
  const { default: RepositoryDetails } = await import(
    /* webpackChunkName: "repository-details" */ "pages/Repositories/RepositoryDetails"
  );
  return {
    element: (
      <IntercomOverlay>
        <RepositoryDetails />
      </IntercomOverlay>
    ),
  };
}

async function lazyAgents() {
  const { default: Agents } = await import(
    /* webpackChunkName: "agents" */ "pages/Agents"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper permissions={[VIEW_AGENTS]}>
          <Agents />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazyAgentDeploymentInstructionPage() {
  const { default: AgentDeploymentInstructionPage } = await import(
    /* webpackChunkName: "agent-deployment" */ "pages/Agents/AgentDeploymentInstructionPage"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper permissions={[VIEW_AGENTS]}>
          <AgentDeploymentInstructionPage />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazyAccessTokensPage() {
  const { default: AccessTokensPage } = await import(
    /* webpackChunkName: "access-tokens" */ "pages/AccessTokens"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper permissions={[VIEW_ACCESS_TOKENS]}>
          <AccessTokensPage />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazyUsersPage() {
  const { default: UsersPage } = await import(
    /* webpackChunkName: "users" */ "pages/Users"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper permissions={[VIEW_USERS]}>
          <UsersPage />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazyUserDetail() {
  const { default: UserDetail } = await import(
    /* webpackChunkName: "user-detail" */ "pages/Users/UserDetail"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper permissions={[VIEW_USERS]}>
          <UserDetail />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazyGroups() {
  const { default: Groups } = await import(
    /* webpackChunkName: "groups" */ "pages/Groups"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper permissions={[VIEW_GROUPS]}>
          <Groups />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazyGroupDetail() {
  const { default: GroupDetail } = await import(
    /* webpackChunkName: "group-detail" */ "pages/Groups/GroupDetail"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper
          permissions={[VIEW_GROUP_MEMBERS, MANAGE_GROUPS]}
        >
          <GroupDetail />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazyPermissions() {
  const { default: Permissions } = await import(
    /* webpackChunkName: "permissions" */ "pages/Permissions/Permissions"
  );
  return {
    element: (
      <IntercomOverlay>
        <Permissions />
      </IntercomOverlay>
    ),
  };
}

async function lazyRolesAndPermissions() {
  const { default: RolesAndPermissions } = await import(
    /* webpackChunkName: "roles-permissions" */ "pages/Permissions/RolesAndPermissions"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper permissions={[VIEW_ROLES]}>
          <RolesAndPermissions />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazyAuditLogs() {
  const { default: AuditLogs } = await import(
    /* webpackChunkName: "audit-logs" */ "pages/AuditLogs"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuditLogs />
      </IntercomOverlay>
    ),
  };
}

async function lazyObservability() {
  const { default: Observability } = await import(
    /* webpackChunkName: "observability" */ "pages/Observability"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper permissions={[VIEW_OBSERVABILITY]}>
          <Observability />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazySecretsManagement() {
  const { default: SecretsManagement } = await import(
    /* webpackChunkName: "secrets-management" */ "pages/SecretsManagement"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper permissions={[VIEW_SECRET_STORES]}>
          <SecretsManagement />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazyGeneralPage() {
  const { default: GeneralPage } = await import(
    /* webpackChunkName: "general" */ "pages/General"
  );
  return {
    element: (
      <IntercomOverlay>
        <AuthorizedPageWrapper permissions={[READ_ORGANIZATION]}>
          <GeneralPage />
        </AuthorizedPageWrapper>
      </IntercomOverlay>
    ),
  };
}

async function lazyPersonalSettings() {
  const { default: PersonalSettings } = await import(
    /* webpackChunkName: "personal-settings" */ "pages/PersonalSettings"
  );
  return {
    element: (
      <IntercomOverlay>
        <PersonalSettings />
      </IntercomOverlay>
    ),
  };
}

async function lazyCheckoutLoader() {
  const { default: CheckoutLoader } = await import(
    /* webpackChunkName: "checkout" */ "pages/Checkout/loader"
  );
  return {
    element: (
      <IntercomOverlay>
        <CheckoutLoader />
      </IntercomOverlay>
    ),
  };
}

async function lazyCheckoutCompletePage() {
  const { default: CheckoutCompletePage } = await import(
    /* webpackChunkName: "checkout-complete" */ "pages/CheckoutComplete"
  );
  return {
    element: (
      <IntercomOverlay>
        <CheckoutCompletePage />
      </IntercomOverlay>
    ),
  };
}

async function lazyGitHubOAuthCallback() {
  const { default: GitHubOAuthCallback } = await import(
    /* webpackChunkName: "github-oauth" */ "pages/components/GithubOAuthCallback"
  );
  return { element: <GitHubOAuthCallback /> };
}

function AccessModeRedirect() {
  const accessMode = useSelector(getAccessMode);
  if (accessMode === AccessMode.VISITOR) {
    return <Navigate to={"/login"} />;
  }
  return <Navigate to={"/home"} />;
}

function TopLevelRoutes() {
  useTracingManager();

  const safeCrash = useSelector(getSafeCrash);
  const safeCrashCode = useSelector(getSafeCrashCode);
  const safeCrashReason = useSelector(getSafeCrashReason);

  if (safeCrash && safeCrashCode) {
    return (
      <Auth0ProviderWithHistory>
        <ErrorPage code={safeCrashCode} reason={safeCrashReason} />
      </Auth0ProviderWithHistory>
    );
  }

  return (
    <Auth0ProviderWithHistory>
      <Outlet />

      {/* These can appear on any page */}
      <LazyDowngradeModal />
      <LazyPricingModal />
      <LazyPurchasedModal />
      <LazyQualificationModal />

      {/* TODO: Remove this once control flow is fully released */}
      <ApiProfileReloader />
    </Auth0ProviderWithHistory>
  );
}

// See https://github.com/remix-run/react-router/discussions/10166
const BubbleError = () => {
  const error = useRouteError();
  throw error;
};

// detect if running in test environment because router depends on some browser apis
export const router = (
  typeof jest !== "undefined" ? createMemoryRouter : createBrowserRouter
)([
  {
    element: <TopLevelRoutes />,
    errorElement: <BubbleError />,
    children: [
      { path: "/login", element: <LoginComponent /> },
      { path: "/signup", element: <SignupComponent /> },
      { path: "/logout", element: <LogoutComponent /> },
      ...(isDev()
        ? [
            {
              path: "/embed/applications/preview/*",
              element: <MemoizedEmbeddedPrivateRoutes />,
              children: [
                {
                  path: ApplicationRoute.Viewer,
                  element: (
                    <AppViewerLoader embedMode={true} previewMode={true} />
                  ),
                },
              ],
            },
            {
              path: "/embed/applications/*",
              element: <MemoizedEmbeddedPrivateRoutes />,
              children: [
                {
                  path: ApplicationRoute.Viewer,
                  element: <AppViewerLoader embedMode={true} />,
                },
              ],
            },
          ]
        : []),
      {
        element: <MemoizedPrivateRoutes />,
        children: [
          {
            path: "folders/:folderId/*",
            lazy: lazyHome,
          },
          {
            path: "integrations",
            lazy: lazyIntegrations,
          },
          {
            path: "profiles",
            lazy: lazyProfiles,
          },
          {
            path: "repositories",
            lazy: lazyRepositories,
          },
          {
            path: "repositories/:repositoryId",
            lazy: lazyRepositoryDetails,
          },
          {
            path: ApplicationRoute.Base,
            children: [
              {
                path: ApplicationRoute.Preview,
                element: <AppViewerLoader previewMode={true} />,
              },
              {
                // Deprecated route
                path: ApplicationRoute.DeprecatedPreview,
                element: <WidgetsPreviewRedirect />,
              },
              {
                // Deprecated route
                path: ApplicationRoute.DeprecatedPageEdit,
                element: <CatchRedirectEditor />,
              },
              {
                path: EditorRoute.EditApplication,
                // Loads the editor layout, contents are filled based on route
                element: <EditorLoader />,
                children: [
                  {
                    path: EditorRoute.DeprecatedEditApiAction,
                    element: <WidgetsEditorRedirect />,
                  },
                  {
                    path: EditorRoute.DeprecatedEditApiInputs,
                    element: <WidgetsEditorRedirect />,
                  },
                  {
                    path: EditorRoute.DeprecatedEditApiResponse,
                    element: <WidgetsEditorRedirect />,
                  },
                  {
                    path: EditorRoute.DeprecatedEditApi,
                    element: <WidgetsEditorRedirect />,
                  },
                  {
                    path: EditorRoute.EditApi,
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: EditorRoute.EditApiActionBase,
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: EditorRoute.EditApiAction,
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: EditorRoute.EditApiInputs,
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: EditorRoute.EditApiResponse,
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: EditorRoute.EditEntityProperty,
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: "",
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: "*",
                    lazy: lazyWidgetEditor,
                  },
                ],
              },
              {
                path: ApplicationRoute.DeprecatedViewer,
                element: <WidgetsDeployedRedirect />,
              },
              {
                path: ApplicationRoute.Viewer,
                element: <AppViewerLoader />,
              },
              {
                path: "*",
                element: <AccessModeRedirect />,
              },
            ],
          },
          {
            path: "home?/*",
            lazy: lazyHome,
          },
          {
            path: "opas",
            lazy: lazyAgents,
          },
          {
            path: "opas/instructions",
            lazy: lazyAgentDeploymentInstructionPage,
          },
          {
            path: "access-tokens",
            lazy: lazyAccessTokensPage,
          },
          {
            path: "users",
            lazy: lazyUsersPage,
          },
          {
            path: "users/:userId/:pageType",
            lazy: lazyUserDetail,
          },
          {
            path: "groups",
            lazy: lazyGroups,
          },
          {
            path: "groups/:groupId/:pageType",
            lazy: lazyGroupDetail,
          },
          {
            path: "permissions/:pageType",
            lazy: lazyPermissions,
          },
          {
            path: "roles-and-permissions",
            element: (
              <Navigate
                to={ROLES_AND_PERMISSIONS_URL(
                  RoleAndPermissionsPages.ORGANIZATION_ROLES,
                )}
                replace
              />
            ),
          },
          {
            path: ROLES_AND_PERMISSIONS_URL(),
            lazy: lazyRolesAndPermissions,
          },
          {
            path: "audit",
            lazy: lazyAuditLogs,
          },
          {
            path: "integrations/:pluginId/:datasourceId",
            lazy: lazyIntegrationForm.bind(null, IntegrationKind.PLUGIN),
          },
          {
            path: "integrations/:pluginId",
            lazy: lazyIntegrationForm.bind(null, IntegrationKind.PLUGIN),
          },
          {
            path: "workflows/:apiId/action?/:actionId?/*",
            async lazy() {
              const { WorkflowEditor } = await import(
                /* webpackChunkName: "WorkflowEditor" */
                "./pages/Editors/ApiEditor/WorkflowEditor"
              );
              return {
                element: <WorkflowEditor key={1} />,
              };
            },
          },
          {
            path: "workflows",
            element: <AccessModeRedirect />,
          },
          {
            path: "scheduled_jobs/:apiId/action?/:actionId?/*",
            async lazy() {
              const { WorkflowEditor } = await import(
                /* webpackChunkName: "WorkflowEditor" */
                "./pages/Editors/ApiEditor/WorkflowEditor"
              );
              return {
                element: <WorkflowEditor key={1} />,
              };
            },
          },
          {
            path: "scheduled_jobs",
            element: <AccessModeRedirect />,
          },
          {
            path: "oauth/github-callback",
            lazy: lazyGitHubOAuthCallback,
          },
          {
            path: "observability/:pageType?",
            lazy: lazyObservability,
          },
          {
            path: "secrets-management",
            lazy: lazySecretsManagement,
          },
          {
            path: "secrets-management/:pluginId/:datasourceId",
            lazy: lazyIntegrationForm.bind(null, IntegrationKind.SECRET),
          },
          {
            path: "secrets-management/:pluginId",
            lazy: lazyIntegrationForm.bind(null, IntegrationKind.SECRET),
          },
          {
            path: "general",
            lazy: lazyGeneralPage,
          },
          {
            path: "personal-settings",
            lazy: lazyPersonalSettings,
          },
          {
            path: "checkout",
            lazy: lazyCheckoutLoader,
          },
          {
            path: "checkout/complete",
            lazy: lazyCheckoutCompletePage,
          },
          {
            path: OAUTH_CALLBACK_PATH,
            element: <OAuthImplicitCallback />,
          },
        ],
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);

const Router = () => {
  return (
    <Suspense fallback={loadingIndicator}>
      <RouterProvider router={router} fallbackElement={<FullPageSpinner />} />
    </Suspense>
  );
};

// Uses basic memoization because routes can't change
export default memo(Router);
