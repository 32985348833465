import { Dimension, Typographies } from "@superblocksteam/shared";
import { IconSize } from "legacy/components/utils/IconUtil";
import { MultiStepDef } from "legacy/constants/ActionConstants";
import { TextStyleWithVariant } from "legacy/themes";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import {
  BooleanStyleFalse,
  ImageSize,
} from "legacy/widgets/Shared/ValueWithTypeUtils";
import {
  CompactModeTypes,
  TextSizes,
  CellAlignmentTypes,
  VerticalAlignmentTypes,
  EditInputType,
  PaginationTypes,
} from "./types";

export type TagDisplayConfig = {
  color: string;
};

export type TableSizes = {
  COLUMN_HEADER_HEIGHT: number;
  TABLE_HEADER_HEIGHT: number;
  TABLE_FOOTER_HEIGHT: number;
  ROW_HEIGHT: number;
  ROW_FONT_SIZE: number;
  PAGINATION_CONTROLS_HEIGHT: number;
  IMAGE_SIZE: number;
  LINK_MENU_HEIGHT: number;
  LINK_MENU_ICON_SIZE: IconSize;
  ROW_PADDING: number;
};

export const JUSTIFY_CONTENT = {
  LEFT: "flex-start",
  CENTER: "center",
  RIGHT: "flex-end",
};

export const ALIGN_ITEMS = {
  TOP: "flex-start",
  CENTER: "center",
  BOTTOM: "flex-end",
};

export const TEXT_ALIGN = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
};

export const TEXT_SIZES: Record<TextSizes, string> = {
  HEADING1: "24px",
  HEADING2: "18px",
  HEADING3: "16px",
  PARAGRAPH: "14px",
  PARAGRAPH2: "12px",
};

export const LEGACY_DEFAULT_CELL_TEXT_SIZE: TextSizes = TextSizes.PARAGRAPH;

export enum FontStyleTypes {
  BOLD = "BOLD",
  ITALIC = "ITALIC",
  REGULAR = "REGULAR",
  UNDERLINE = "UNDERLINE",
}

export const TABLE_SIZES: Record<CompactModeTypes, TableSizes> = {
  [CompactModeTypes.VERY_SHORT]: {
    COLUMN_HEADER_HEIGHT: 48,
    TABLE_HEADER_HEIGHT: 60,
    TABLE_FOOTER_HEIGHT: 48,
    ROW_HEIGHT: 28,
    ROW_FONT_SIZE: 14,
    PAGINATION_CONTROLS_HEIGHT: 24,
    IMAGE_SIZE: 22,
    LINK_MENU_HEIGHT: 28,
    LINK_MENU_ICON_SIZE: IconSize.LARGE,
    ROW_PADDING: 0,
  },
  [CompactModeTypes.SHORT]: {
    COLUMN_HEADER_HEIGHT: 48,
    TABLE_HEADER_HEIGHT: 60,
    TABLE_FOOTER_HEIGHT: 48,
    ROW_HEIGHT: 36,
    ROW_FONT_SIZE: 14,
    PAGINATION_CONTROLS_HEIGHT: 24,
    IMAGE_SIZE: 28,
    LINK_MENU_HEIGHT: 32,
    LINK_MENU_ICON_SIZE: IconSize.LARGE,
    ROW_PADDING: 2,
  },
  [CompactModeTypes.DEFAULT]: {
    COLUMN_HEADER_HEIGHT: 48,
    TABLE_HEADER_HEIGHT: 60,
    TABLE_FOOTER_HEIGHT: 48,
    ROW_HEIGHT: 48,
    ROW_FONT_SIZE: 14,
    PAGINATION_CONTROLS_HEIGHT: 32,
    IMAGE_SIZE: 32,
    LINK_MENU_HEIGHT: 36,
    LINK_MENU_ICON_SIZE: IconSize.XL,
    ROW_PADDING: 5,
  },
  [CompactModeTypes.TALL]: {
    COLUMN_HEADER_HEIGHT: 36,
    TABLE_HEADER_HEIGHT: 60,
    TABLE_FOOTER_HEIGHT: 60,
    ROW_HEIGHT: 58,
    ROW_FONT_SIZE: 14,
    PAGINATION_CONTROLS_HEIGHT: 36,
    IMAGE_SIZE: 36,
    LINK_MENU_HEIGHT: 36,
    LINK_MENU_ICON_SIZE: IconSize.XL,
    ROW_PADDING: 10,
  },
};

export const DEFAULT_TABLE_COLUMN_WIDTH = 150;

export const TABLE_COLUMN_MIN_WIDTH = 20;

export const TABLE_ROW_LEFT_PADDING = 14;

export const TABLE_ROW_RIGHT_PADDING = 14;

export enum ColumnTypes {
  DATE = "date",
  VIDEO = "video",
  IMAGE = "image",
  TEXT = "text",
  NUMBER = "number",
  CURRENCY = "currency",
  PERCENTAGE = "percentage",
  BOOLEAN = "boolean",
  TAGS = "tags",
  BUTTON = "button",
  EMAIL = "email",
  LINK = "link",
}

export interface TableStyles {
  cellBackground?: string;
  textColor?: string;
  textSize?: TextSize;
  fontStyle?: string;
  horizontalAlignment?: CellAlignment;
  verticalAlignment?: VerticalAlignment;
}

export type CompactMode = keyof typeof CompactModeTypes;
export type Pagination = keyof typeof PaginationTypes;
type CellAlignment = keyof typeof CellAlignmentTypes;
type VerticalAlignment = keyof typeof VerticalAlignmentTypes;
type TextSize = keyof typeof TextSizes;

export interface TableSortColumn {
  column: string;
  asc: boolean;
}

export interface TagsColorAssignment {
  uniqueTagsCount: number;
  // map each tag to a unique index number corresponding to the order which each unique tag was discovered
  // we can then translate that index number to a color by consulting the color palette
  mapping: Record<string, number>;
  // invariant: uniqueTagsCount should be equal to the number of elements in mapping
  // i.e.: Object.keys(mapping).length === uniqueTagsCount
  // the only reason for storing this number is to avoid the linear time calculation of it
}

export type SingleCellProperties = ColumnPropertiesForEditing & {
  horizontalAlignment?: CellAlignment;
  verticalAlignment?: VerticalAlignment;
  textSize?: TextSize;
  fontStyle?: string;
  textColor?: string;
  cellTextStyle?: TextStyleWithVariant;
  cellBackground?: string;
  buttonBackgroundColor?: string;
  buttonVariant?: string;
  buttonLabelColor?: string;
  buttonLabel?: string;
  cellIcon?: string;
  cellIconPosition?: string;
  linkUrl?: string;
  linkLabel?: string;
  openInNewTab?: boolean;
  isDisabled?: boolean;
  displayedValue?: string;
  textWrap?: boolean;
  dropdownIcon?: string;
  imageBorderRadius?: Dimension;
  imageSize?: ImageSize;
  openImageUrl?: boolean;
  // date type
  inputFormat?: string;
  outputFormat?: string;
  manageTimezone?: boolean;
  timezone?: string;
  displayTimezone?: string;
};

interface TableColumnMetaProps {
  isHidden: boolean;
  type: string;
}

export interface ReactTableColumnProps {
  id: string;
  Header: string;
  accessor: (data: any) => any;
  width?: number;
  actualFlexWidth?: number;
  minWidth: number;
  draggable: boolean;
  isHidden?: boolean;
  isAscOrder?: boolean;
  metaProperties?: TableColumnMetaProps;
  isDerived?: boolean;
  columnProperties: ColumnProperties;
  Cell: (props: any) => JSX.Element;
  sticky?: string;
}

interface ColumnPropertiesForEditing {
  manageTimezone?: boolean;
  timezone?: string;
  displayTimezone?: string;
  dropdownOptions?: string;
  notation?: Intl.NumberFormatOptions["notation"] | "unformatted";
  currency?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

// When using a {{ binding you get an array of values
export interface ColumnProperties
  extends ColumnEditConfigs,
    ColumnPropertiesForEditing {
  id: string;
  label: string;
  columnType: string;
  headerIcon?: string;
  headerIconPosition?: string;
  isVisible: boolean;
  index: number;
  width: number;
  cellBackground?: string;
  horizontalAlignment?: CellAlignment;
  verticalAlignment?: VerticalAlignment;
  textSize?: TextSize;
  fontStyle?: string;
  textColor?: string;
  cellProps?: {
    textStyle: TextStyleWithVariant;
  };
  enableFilter?: boolean;
  enableSort?: boolean;
  isDerived: boolean;
  computedValue: string | string[];
  displayedValue?: string | string[];
  buttonLabel?: string;
  buttonStyle?: string;
  buttonVariant?: string;
  buttonLabelColor?: string;
  cellIcon?: string;
  cellIconPosition?: string;
  isDisabled?: boolean;
  linkUrl?: string;
  linkLabel?: string;
  openInNewTab?: boolean;
  onClick?: MultiStepDef;
  onDropdownSearchTextChanged?: MultiStepDef;
  dropdownOptions?: string;
  onOptionChange?: MultiStepDef;
  onFocusOut?: MultiStepDef;
  onDateSelected?: MultiStepDef;
  onCheckChange?: MultiStepDef;
  booleanStyleFalse?: BooleanStyleFalse;
  isEditable?: boolean | string; // string for {{ bindings
  isFrozen?: boolean;
  textWrap?: boolean;
  tagDisplayConfig: Record<string, TagDisplayConfig>;
  //image cell props
  imageSize?: ImageSize;
  imageBorderRadius?: Dimension;
  openImageUrl?: boolean;
  // for date columns
  outputFormat?: string | string[];
  inputFormat?: string | string[];
}

export type EditProps = ColumnEditConfigs & {
  handleEditStart: () => void;
  handleEditChange: (
    value: any,
    dropdownOptions?: Array<{
      label: string;
      value: string;
    }>,
  ) => void;
  handleEditStop: (props: {
    shouldSave: boolean;
    value?: any;
    validationErrors?: Array<string>;
    moveFocus?: "UP" | "DOWN" | "LEFT" | "RIGHT";
    overrideEditFocus?: { row: number; columnId: string };
    dropdownOption?: { label: string; value: string };
  }) => void;
  handleOneClickEdit: (value: any) => void;
  isEditFocused: boolean;
  isEdited: boolean;
  onDropdownSearchTextChanged?: (value: string) => void;
  onDropdownIconChange?: (iconName: string) => void;
  currentEditDropdownSearchText?: string;
  dropdownOptionsLoading?: boolean;
  validationErrors: Array<string>;
  currentEditValue: any;
};

type ColumnEditConfigs = {
  editInputType?: string;
  editCustomValidationRule?: string;
  editCustomErrorMessage?: string;
  editMaxLength?: number;
  editMinLength?: number;
  editIsRequired?: boolean;
  editMultiSelect?: boolean;
  allowClearing?: boolean;
  editDropdownClientSideFiltering?: boolean;
  editOptions?: string | Array<string | { label: string; value: string }>;
  useLabelAsDisplayValue?: boolean;
  transformation?: undefined | { label: string; value: string };
  editMinDate?: string;
  editMaxDate?: string;
  editTwentyFourHourTime?: boolean;
  defaultValue?: any;
  isEditableOnInsertion?: boolean;
  isRequiredOnInsertion?: boolean;
};

// Edit input types where the value should be parsed from a string to JSON before saving
export const PARSED_INPUT_TYPES = [
  EditInputType.Number,
  EditInputType.Checkbox,
];

export const TextBoxEditTypes = [
  EditInputType.Text,
  EditInputType.Number,
  EditInputType.Email,
];

export type InsertRowOptions = {
  beforeRowIndex?: number;
  insertedBelow?: boolean;
  isDuplication?: boolean;
};

export const DEFAULT_CELL_TEXT_STYLE_VARIANT: keyof Typographies = "body2";
export const DEFAULT_CELL_CLASS_NAME = getTextCssClassFromVariant(
  DEFAULT_CELL_TEXT_STYLE_VARIANT,
);

export const DEFAULT_HEADING_TEXT_STYLE_VARIANT: keyof Typographies =
  "heading3";

export const TABLE_COLUMN_HEADER_DEFAULT_TYPOGRAPHY: keyof Typographies =
  "heading6";
