import { Modal, ModalProps } from "antd";
import { clsx } from "clsx";
import React, { forwardRef, MouseEvent, ReactNode } from "react";
import { ReactComponent as ErrorCircleIcon } from "assets/icons/common/error-circle.svg";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import {
  DangerButton,
  DangerButtonCssString,
  PrimaryButtonCssString,
  SecondaryButton,
  SecondaryButtonCssString,
} from "./Button";

export const DEFAULT_MODAL_WIDTH = 384;

export const ModalWrapClass = styleAsClass`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 12px;

    input {
      font-size: 12px;
    }
  }

  .ant-modal-header {
    padding: 14px 16px;
    display: flex;
    align-items: center;
    max-height: 48px;
  }

  .ant-modal-title {
    font-size: 15px;
    line-height: 1.333;
    font-weight: 600;
    color: ${colors.GREY_900};
  }

  .ant-modal-close-x {
    width: 48px;
    height: unset;
    aspect-ratio: 1;
    display: inline-grid;
    place-items: center;
    font-size: 12px;
  }

  .ant-modal-body {
    padding: 0 16px;
    font-size: 12px;

    input {
      font-size: 12px;
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }

  .ant-modal-body .ant-form-item:last-of-type {
    margin-bottom: 0;
  }

  .ant-modal-footer {
    border-top: unset;
    padding: 0 16px 16px;
  }
`;

export const ModalInnerWrapper = styleAsClass`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

export const FooterWrapper = styleAsClass`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

export const FooterWrapperWide = styleAsClass`
  display: grid;
  justify-content: space-between;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
`;

export const ModalTitleWrapper = styleAsClass`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface ContentProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "className"> {
  "data-test"?: string | boolean | number;
}

interface BaseModalProps extends ModalProps {
  contentClassName?: string;
  contentProps?: ContentProps;
  footerClassName?: string;
  footerVariant?: "wide" | "normal";
  titleClassName?: string;
}

export const BaseModal = forwardRef<HTMLDivElement, BaseModalProps>(
  function BaseModal(props, ref) {
    const {
      contentClassName,
      contentProps,
      destroyOnClose = true,
      footerClassName,
      footerVariant = "wide",
      width = DEFAULT_MODAL_WIDTH,
    } = props;

    return (
      <Modal
        {...props}
        destroyOnClose={destroyOnClose}
        wrapClassName={clsx(ModalWrapClass, props.wrapClassName)}
        width={width}
        title={
          props.title ? (
            <div className={clsx(ModalTitleWrapper, props.titleClassName)}>
              {props.title}
            </div>
          ) : (
            props.title
          )
        }
        footer={
          props.footer ? (
            <div
              className={clsx(
                {
                  [FooterWrapperWide]: footerVariant === "wide",
                  [FooterWrapper]: footerVariant === "normal",
                },
                footerClassName,
              )}
            >
              {props.footer}
            </div>
          ) : (
            props.footer
          )
        }
      >
        <div
          {...contentProps}
          className={clsx(ModalInnerWrapper, contentClassName)}
          ref={ref}
        >
          {props.children}
        </div>
      </Modal>
    );
  },
);

BaseModal.displayName = "ForwardRef(BaseModal)";

const DeleteMessage = styleAsClass`
  font-size: 13px !important;
  line-height: 1.538;
  color: ${colors.GREY_700};
  display: block;

  b {
    font-weight: 500;
  }

  & *:last-child {
    margin-bottom: 0;
  }
`;

export const ErrorWrapper = styleAsClass`
  color: ${colors.RED_500};
`;

export const ConfirmModalClass = styleAsClass`
  width: 384px;
  &.ant-modal-confirm .ant-modal-body {
    padding: 16px;
  }
  .ant-modal-confirm-body {
    display: grid;
    gap: 0px;
    align-items: center;
    grid-template-columns: 32px 1fr;
    grid-template-rows: auto;

    & > svg:first-child {
      margin-top: -8px;
    }

    .ant-modal-confirm-title {
      margin-top: -6px;
      padding: 4px 0px;
    }

    & > .ant-modal-confirm-title:first-child {
      grid-column: 1 / span 2; // only title and no icon, title takes full width of first row
    }

    & > .ant-modal-confirm-title:nth-child(2) ,  & > .ant-modal-confirm-content:nth-child(2) {
      margin-left: 8px;
      margin-top: -6px;
    }

    .ant-modal-confirm-content:empty {
      border: none !important;
      padding: 0px !important;
      margin: 0px !important;
    }

    & > .ant-modal-confirm-content:only-child {
      grid-column: 1 / span 2;
    }

    & > .ant-modal-confirm-content:nth-child(3) {
      margin-top: 8px;
      grid-column: 1 / span 2; // this is content, and there is icon and title, so span across both columns
    }

    & > .ant-modal-confirm-title + .ant-modal-confirm-content {
      border-top: 1px solid ${colors.GREY_100};
      padding: 16px 16px 0 16px;
      margin-left: -16px;
      margin-right: -16px;
      margin-top: 8px;
      grid-column: 1 / span 2; // only title and content, content takes full width of second ro
    }

  }

  .ant-modal-confirm-body > svg:first-child {
    flex: 0 0 32px;
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: ${colors.GREY_900};
    width: 100%;
  }

  .ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 0px;
    font-size: 13px;
    line-height: 20px;
    .ant-typography {
     color: ${colors.GREY_700};
    }
    color: ${colors.GREY_700};
    word-break: break-word;
  }

  .ant-modal-confirm-btns {
    margin-top: 20px !important;
    display: grid;
    justify-content: space-between;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
  }

  .ant-btn.ant-btn-default {
    ${SecondaryButtonCssString}
  }

  .ant-btn.ant-btn-primary {
    ${PrimaryButtonCssString}
  }

  .ant-btn.ant-btn-default.ant-btn-dangerous {
    ${DangerButtonCssString}
  }

  .ant-modal-close-x {
    width: 48px;
    height: 48px;
    font-size: 13px;
    line-height: 48px;
    color: ${colors.GREY_300};
  }
`;

interface DeleteModalProps extends BaseModalProps {
  cancelButtonText?: string;
  dataTestName?: string;
  deleteButtonText?: string;
  disableDelete?: boolean;
  error?: string;
  isDeleting: boolean;
  onCancel: (event?: MouseEvent<HTMLElement>) => void;
  onDelete: (event?: MouseEvent<HTMLElement>) => void;
}

export const DeleteModal = (props: DeleteModalProps) => {
  const {
    cancelButtonText = "Cancel",
    children,
    dataTestName,
    deleteButtonText = "Delete",
    disableDelete,
    error,
    isDeleting,
    onCancel,
    onDelete,
    title,
  } = props;

  return (
    <BaseModal
      {...props}
      title={<DeleteModalHeader title={title} />}
      footer={
        <>
          <SecondaryButton
            onClick={onCancel}
            loading={isDeleting}
            data-test={`cancel-delete-${dataTestName ?? ""}`}
          >
            {cancelButtonText}
          </SecondaryButton>
          <DangerButton
            onClick={onDelete}
            loading={isDeleting}
            data-test={`confirm-delete-${dataTestName ?? ""}`}
            disabled={disableDelete}
          >
            {deleteButtonText}
          </DangerButton>
        </>
      }
    >
      <div className={DeleteMessage}>{children}</div>
      {error && <div className={ErrorWrapper}>{error}</div>}
    </BaseModal>
  );
};

export const DeleteModalHeader = ({ title }: { title: ReactNode }) => (
  <>
    <ErrorCircleIcon />
    <div>{title}</div>
  </>
);
