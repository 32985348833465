import { createAction } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";

export const resetStateVar = createAction(
  "RESET_STATE_VAR",
  (scope: ApplicationScope, id: string) => ({
    payload: {
      scope,
      id,
    },
  }),
);

export const setStateVarValue = createAction(
  "SET_VALUE_STATE_VAR",
  (
    scope: ApplicationScope,
    id: string,
    value: any,
    skipPersist: boolean = false,
  ) => ({
    payload: {
      scope,
      id,
      value,
      skipPersist,
    },
  }),
);

export const setPropertyStateVar = createAction(
  "SET_PROPERTY_STATE_VAR",
  (scope: ApplicationScope, id: string, path: string, value: any) => ({
    payload: {
      scope,
      id,
      path,
      value,
    },
  }),
);
