import memoizeOne from "memoize-one";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { CanvasWidgetsReduxState } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { getFlattenedCanvasWidgets } from "legacy/selectors/editorSelectors";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import { getDynamicLayoutWidgets } from "legacy/selectors/layoutSelectors";
import { getRelativeStackedWidgetPositions } from "legacy/utils/StackWidgetUtils";
import { generateDropTargetElementId } from "legacy/utils/generators";
import { childStackItemElementId } from "./utils";
import type { AppState } from "store/types";

export type ChildPosInfos = Record<string, [pos: DOMRect, origIndex: number]>;

export const useGetSelectedStackDragPositions = () => {
  const selectedWidgetIds = useSelector(
    (state: AppState) => state.legacy.ui.widgetDragResize.selectedWidgets,
  );
  const flattenedWidgets = useSelector((state: AppState) => {
    return getFlattenedCanvasWidgets(state);
  });

  const dynamicWidgetsLayoutState = useSelector(getDynamicLayoutWidgets);

  return useMemo(
    () =>
      memoizeOne(
        (
          primaryWidgetId?: string,
          parentColumnSpace = GridDefaults.DEFAULT_GRID_COLUMN_WIDTH,
        ) =>
          getRelativeStackedWidgetPositions({
            primaryWidgetId: primaryWidgetId ?? selectedWidgetIds[0],
            widgetIds: selectedWidgetIds,
            widgets: flattenedWidgets,
            dynamicWidgetsLayoutState,
            parentColumnSpace,
          }),
      ),
    [selectedWidgetIds, flattenedWidgets, dynamicWidgetsLayoutState],
  );
};

export const getChildrenPositionInfo = (
  parentWidgetId: string,
  widgets: CanvasWidgetsReduxState,
) => {
  const parentWidget = widgets[parentWidgetId];
  const childWidgets = parentWidget?.children ?? [];
  const childRects = childWidgets.reduce((acc: ChildPosInfos, childId, idx) => {
    const childRect = document
      .getElementById(childStackItemElementId(childId))
      ?.getBoundingClientRect();
    if (!childRect) return acc;
    acc[childId] = [childRect, idx];
    return acc;
  }, {});
  return childRects;
};

export const useDropClientRects = () => {
  const widgets = useSelector(getWidgets);

  const getBasePositionInfo = useCallback((parentWidgetId: string) => {
    const baseRect = document
      .getElementById(generateDropTargetElementId(parentWidgetId))
      ?.getBoundingClientRect();
    return baseRect;
  }, []);

  const getChildrenPositionInfoInternal = useCallback(
    (parentWidgetId: string) => {
      return getChildrenPositionInfo(parentWidgetId, widgets);
    },
    [widgets],
  );

  return {
    getBasePositionInfo,
    getChildrenPositionInfo: getChildrenPositionInfoInternal,
  };
};
